import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

const DEBOUNCE_TIME = 1000

let cache = {}
let timeout

export default function (quizActions, autosave) {
  const updateGraphQuestionPosition = ({ quiz, questionId, position }) => {
    if (timeout) {
      timeout = clearTimeout(timeout)
    }

    const prevValues = quiz.graphQuestionsPositions || {}

    if (!isEqual(prevValues[questionId], position)) {
      // Start or re trigger autosave so it waits for position change to finish
      autosave({ quizId: quiz.id })

      cache[questionId] = position
      // Wait before applying changes because one quiz its updated will re render graph and finish dragging
      timeout = setTimeout(() => {
        const graphQuestionsPositions = pick(
          {
            ...prevValues,
            ...cache,
          },
          [0, ...quiz.questions.map((q) => q.id)] // Filter in case a question was deleted
        )

        quizActions.update({
          quizId: quiz.id,
          change: {
            graphQuestionsPositions,
          },
        })

        cache = {}
      }, DEBOUNCE_TIME)
    }
  }

  return { updateGraphQuestionPosition }
}
