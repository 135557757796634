import { createResult, updateResult, removeResult, createRevision } from 'src/services/api'

import { fileUploads, setQuizUpdateInfo } from './common'

export default function (quiz, state, api) {
  const createAndSaveResult = ({ quiz: localQuiz, result }) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session

    return fileUploads({ id: localQuiz.id, results: [result] }, token)
      .then((res) => createResult(localQuiz.id, res.results[0], token))
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.results.push(res.result)
        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const updateAndSaveResult = ({ quiz: localQuiz, result }) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session

    return fileUploads({ id: localQuiz.id, results: [result] }, state.session.token)
      .then((res) => updateResult(localQuiz.id, res.results[0], state.session.token))
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.results = localQuiz.results.map((r) => (r.id !== res.result.id ? r : res.result))

        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  const removeAndSaveResult = ({ quiz: localQuiz, resultId }) => {
    api.loading({ name: 'quiz/save' })

    const { token } = state.session

    removeResult(localQuiz.id, resultId, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.results = localQuiz.results.filter((q) => q.id !== resultId)
        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  return { createAndSaveResult, updateAndSaveResult, removeAndSaveResult }
}
