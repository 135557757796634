import { api } from './common'

export const createInterstitial = (quizId, interstitial, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/${quizId}/interstitial`,
    token,
    body: interstitial,
  })

export const updateInterstitial = (quizId, interstitial, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/interstitial/${interstitial.id}`,
    token,
    body: interstitial,
  })

export const removeInterstitial = (quizId, id, token) =>
  api({
    method: 'DELETE',
    path: `/builder/quiz/${quizId}/interstitial/${id}`,
    token,
  })
