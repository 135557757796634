import { api } from './common'

export const fileUpload = (imageType, file, token) => {
  const body = new FormData()
  body.append('picture', file)

  return api({
    token,
    method: 'POST',
    path: `/files/image?imageType=${imageType}`,
    formData: true,
    body,
  })
}

export const fileDelete = (imageType, url, quizId, itemId, token) =>
  api({
    token,
    method: 'POST',
    path: `/files/delete?imageType=${imageType}`,
    body: {
      url,
      quizId,
      itemId,
    },
  })
