import { joinQuizChannel, leaveQuizChannel } from 'src/services/websocket'

import { getQuizByCollectionFromState } from 'src/store/reducers/quizUtils'

export default function (quizActions, state) {
  const joinChannel = (quizCollectionId) => {
    const { user } = state.session

    joinQuizChannel(user.id, quizCollectionId)
  }

  const leaveChannel = (quizCollectionId) => {
    const { user } = state.session

    leaveQuizChannel(user.id, quizCollectionId)
  }

  const quizChange = (quiz, userId) => {
    // @TODO It should compare the updateAt Date but a bug on the store is not pulling the updated date
    // const stateQuiz = getQuizFromState(state.quiz, quiz.id)
    // if (stateQuiz.updatedAt < quiz.updatedAt) {
    if (state.session?.user?.id !== userId) {
      const storeQuiz = getQuizByCollectionFromState(quiz.quizCollectionId)
      if (storeQuiz && storeQuiz.id !== quiz.id) {
        quizActions.swapQuiz({ oldId: storeQuiz.id, newQuiz: quiz })
      } else {
        quizActions.replaceQuiz({ quiz })
      }
    }
  }

  return { joinChannel, leaveChannel, quizChange }
}
