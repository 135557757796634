import { api } from './common'

export const createQuestion = (quizId, question, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/${quizId}/question`,
    token,
    body: question,
  })

export const updateQuestion = (quizId, question, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/question/${question.id}`,
    token,
    body: question,
  })

export const removeQuestion = (quizId, questionId, token) =>
  api({
    method: 'DELETE',
    path: `/builder/quiz/${quizId}/question/${questionId}`,
    token,
  })
