import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 200,
  },
}))

const propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
}

const Loading = ({ loading, message = '', className }) => {
  const classes = useStyles()

  if (!loading) {
    return null
  }

  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress />
      <Typography variant="body1">{message}</Typography>
    </div>
  )
}

Loading.propTypes = propTypes

export default Loading
