export const quizStatus = {
  deleted: 'deleted',
  published: 'published',
  draft: 'draft',
}

export const labelsStatus = {
  deleted: 'Deleted',
  published: 'Published',
  draft: 'Draft',
}

export const getStatusLabel = (status) => labelsStatus[status] || 'Unknown Status'

export const quizSections = Object.freeze({
  ABOUT: 'about',
  TEMPLATE: 'template',
  WEIGHTS: 'weights',
  STYLES: 'styles',
})

export const quizEntities = Object.freeze({
  QUIZ: 'quiz',
  QUESTION: 'quizQuestion',
  RESULT: 'quizResult',
  INTERSTITIAL: 'quizInterstitial',
})

export const defaultQuizMetadata = Object.freeze({
  LOCATION_CITY: 'LOCATION_CITY',
  LOCATION_STATE: 'LOCATION_STATE',
  LOCATION_COUNTRY: 'LOCATION_COUNTRY',
})

export const quizListSections = Object.freeze({
  ALL: '',
  DELETED: 'deleted',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  RECENT: 'recent',
})

// Relate to /shared/enums/TransitionTypes.ts
export const quizTransitionTypes = Object.freeze({
  FADE: 'fade',
  BLUR: 'blur',
  FLY: 'fly',
  SLIDE: 'slide',
  SCALE: 'scale',
})

// Relate to /shared/enums/ShowProgressPositions.ts
export const quizShowProgressPositions = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
})

export const slackNotificationTypes = Object.freeze({
  current_only: 'current_only',
  before_all: 'before_all',
})

export const slackNotificationTypesName = Object.freeze({
  [slackNotificationTypes.current_only]: 'Include answers from this question only',
  [slackNotificationTypes.before_all]: 'Include answers from all questions up to this point',
})

export const sequencingTypes = Object.freeze({
  MASTER: 'master',
  BRANCH: 'branch',
  SKIP: 'skip',
})
