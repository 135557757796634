import { api } from './common'

const getStatistics = (type) => ({ quizId, dateRange, platforms, campaigns, keywords, token }) => {
  const query = []
  dateRange?.startDate && query.push(`startDate=${dateRange.startDate.toISOString()}`)
  dateRange?.endDate && query.push(`endDate=${dateRange.endDate.toISOString()}`)
  platforms && platforms.forEach((item) => query.push(`platforms[]=${item}`))
  campaigns && campaigns.forEach((item) => query.push(`campaigns[]=${item}`))
  keywords && keywords.forEach((item) => query.push(`keywords[]=${item}`))

  return api({
    method: 'GET',
    path: `/statistics/${type}/${quizId}${query.length ? `?${query.join('&')}` : ''}`,
    token,
  })
}

export const getSummaryStatistics = getStatistics('summary')

export const getFiltersStatistics = getStatistics('filters')

export const getQuestionsStatistics = getStatistics('questions')

export const getAllQuestions = getStatistics('all/questions')

export const getResultsStatistics = getStatistics('results')

export const getCTAStatistics = getStatistics('cta')
