import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { LinearProgress } from '@material-ui/core'

const propTypes = {
  route: PropTypes.object,
}

function Empty({ route }) {
  return <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
}

Empty.propTypes = propTypes

export default Empty
