import {
  createInterstitial,
  updateInterstitial,
  removeInterstitial,
  createRevision,
} from 'src/services/api'

import { endpointGroups } from 'src/constants/api'
import { setQuizUpdateInfo } from './common'

export default function (quiz, state, api) {
  const createAndSaveInterstitial = ({ quiz: localQuiz, interstitial }) => {
    api.loading({ name: endpointGroups.QUIZ_INTERSTITIAL })

    const { token } = state.session
    return createInterstitial(localQuiz.id, interstitial, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.interstitials.push(res.interstitial)
        setQuizUpdateInfo(localQuiz, state.session.user)

        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_INTERSTITIAL, error })
        throw error
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_INTERSTITIAL })
      })
  }

  const updateAndSaveInterstitial = ({ quiz: localQuiz, interstitial }) => {
    api.loading({ name: endpointGroups.QUIZ_INTERSTITIAL })

    const { token } = state.session
    return updateInterstitial(localQuiz.id, interstitial, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.interstitials = localQuiz.interstitials
          .filter((i) => i.id !== res.interstitial.id)
          .concat(res.interstitial)
        setQuizUpdateInfo(localQuiz, state.session.user)

        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_INTERSTITIAL, error })
        throw error
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_INTERSTITIAL })
      })
  }

  const removeAndSaveInterstitial = ({ quiz: localQuiz, id }) => {
    api.loading({ name: endpointGroups.QUIZ_INTERSTITIAL })

    const { token } = state.session
    return removeInterstitial(localQuiz.id, id, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.interstitials = localQuiz.interstitials.filter((i) => i.id !== id)
        setQuizUpdateInfo(localQuiz, state.session.user)

        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_INTERSTITIAL, error })
        throw error
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_INTERSTITIAL })
      })
  }

  const createAndSaveInterstitialPage = ({ quiz: localQuiz, interstitial, page }) => {
    const updatedInterstitial = {
      ...interstitial,
      pages: [...(interstitial.pages || []), { ...page, id: undefined }],
    }

    return updateAndSaveInterstitial({ quiz: localQuiz, interstitial: updatedInterstitial })
  }

  const updateAndSaveInterstitialPage = ({ quiz: localQuiz, interstitial, page }) => {
    const updatedInterstitial = {
      ...interstitial,
      pages: (interstitial.pages || []).filter((p) => p.id !== page.id).concat(page),
    }

    return updateAndSaveInterstitial({ quiz: localQuiz, interstitial: updatedInterstitial })
  }

  const removeAndSaveInterstitialPage = ({ quiz: localQuiz, interstitial, pageId }) => {
    const updatedInterstitial = {
      ...interstitial,
      pages: (interstitial.pages || []).filter((p) => p.id !== pageId),
    }

    return updateAndSaveInterstitial({ quiz: localQuiz, interstitial: updatedInterstitial })
  }

  return {
    createAndSaveInterstitial,
    updateAndSaveInterstitial,
    removeAndSaveInterstitial,
    createAndSaveInterstitialPage,
    updateAndSaveInterstitialPage,
    removeAndSaveInterstitialPage,
  }
}
