import {
  getLockStatus,
  setLockStatus,
  publishQuiz,
  unlockQuiz,
  createRevision,
} from 'src/services/api'

import { genNewQuiz } from './common'

export default function (quiz, state, api) {
  const getLock = ({ quizId, entity, id, section = '' }) => {
    api.loading({ name: 'quiz/lock' })

    return getLockStatus(quizId, entity, id, section, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        return res.lockedBy
      })
      .catch((error) => {
        api.error({ name: 'quiz/lock', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/lock' })
      })
  }

  const setLock = ({ quizId, entity, id, lock, section = '' }) => {
    api.loading({ name: 'quiz/lock' })

    return setLockStatus(quizId, entity, id, lock, section, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        return res.lockedBy
      })
      .catch((error) => {
        api.error({ name: 'quiz/lock', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/lock' })
      })
  }

  const publish = (localQuiz, revisionData = {}) => {
    api.loading({ name: 'quiz/publish' })

    const { token } = state.session

    return publishQuiz(localQuiz.id, localQuiz.quizCollectionId, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        const newQuiz = { ...localQuiz, ...res }
        quiz.swapQuiz({ oldId: localQuiz.id, newQuiz })
        createRevision({ ...revisionData, quiz: newQuiz }, token)

        return newQuiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/publish', error })
      })
      .finally(() => {
        api.finish({ name: 'quiz/publish' })
      })
  }

  const unlock = (data) => {
    api.loading({ name: 'quiz/save' })

    const newQuiz = genNewQuiz(state, data, data.ownerUserId)

    return unlockQuiz(data.id, newQuiz, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        quiz.swapQuiz({ oldId: data.id, newQuiz: res.quiz })

        return res.quiz
      })
      .catch((error) => {
        api.error({ name: 'quiz/save', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/save' })
      })
  }

  return { getLock, setLock, publish, unlock }
}
