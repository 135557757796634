import { getChangesCount } from 'src/store/reducers/quizUtils'

export default (state) => ({
  list: () => state.get('list').toJS(),
  get: (id) => {
    const idx = state.get('list').findIndex((quiz) => quiz.get('id')?.toString() === id)

    if (idx === -1) {
      return undefined
    }

    return state.getIn(['list', idx]).delete('changes').delete('local').delete('new').toJS()
  },
  getByCollectionId: (collectionId) => {
    // Get a quiz by its collection id
    const collection = state
      .get('list')
      .filter((quiz) => quiz.get('quizCollectionId')?.toString() === collectionId.toString())

    const quiz = collection.maxBy((q) => q.get('id'))
    if (!quiz) {
      return undefined
    }

    return quiz.delete('changes').delete('local').delete('new').toJS()
  },
  getChanges: (id) => {
    const idx = state.get('list').findIndex((quiz) => quiz.get('id')?.toString() === id)

    if (idx === -1) {
      return undefined
    }

    return state.getIn(['list', idx, 'changes'])?.toJS()
  },
  question: (quizId, questionId) => {
    const idx = state.get('list').findIndex((quiz) => quiz.get('id') === quizId)

    if (idx === -1) {
      return undefined
    }

    const qIdx = state
      .getIn(['list', idx, 'questions'])
      .findIndex((q) => q.get('id') === questionId)
    return state.getIn(['list', idx, 'questions', qIdx])
  },
  changesCount: (quizId = '') => {
    if (!quizId) {
      return 0
    }

    return getChangesCount(state, parseInt(quizId, 10))
  },
  getQuizNotifications: (quizId) => {
    if (quizId) {
      const val = state.getIn(['notifications', quizId.toString()])
      return val ? val.toJS() : {}
    }
    return {}
  },
})
