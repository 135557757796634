import merge from 'lodash/merge'

import { questionTypes } from 'src/constants'

const checkUrl = (url) => {
  if (url) {
    return fetch(url, { method: 'HEAD' })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(new Error('URL Not Found'))
        }

        return true
      })
      .catch(() => Promise.reject(new Error('URL Not Found')))
  }

  return Promise.resolve(true)
}

export const questionHasImage = (question) => {
  const result = {}

  if (
    question.questionType === questionTypes.singleChoice ||
    question.questionType === questionTypes.multiChoice
  ) {
    // Verify if image are set on the imageUrl of the answer options
    const imageOnEveryAnswer = question.answerOptions.every((ao) => ao.imageUrl)
    if (!imageOnEveryAnswer) {
      result[`question-${question.id}-missing-image`] = {
        type: 'warning',
        title: 'Missing Image',
        message: `Question ${question.title} doesn't have images. Consider adding them.`,
        createdAt: Date.now(),
      }
    }

    // Verify if there are images on the answer options can be fetch
    const imageExist = Promise.all(question.answerOptions.map((ao) => checkUrl(ao.imageUrl))).catch(
      () => {
        result[`question-${question.id}-image-not-found`] = {
          type: 'error',
          title: '404 Error',
          message: `Question ${question.title} has a bad URL. Please check that the URL works`,
          createdAt: Date.now(),
        }
      }
    )

    return imageExist.then(() => result)
  }

  return Promise.resolve(result)
}

export const quizAllChecks = (quiz) => {
  const results = {}

  const questionResults = Promise.all(quiz.questions.map(questionHasImage)).then((res) => {
    return merge(results, ...res)
  })

  return Promise.all([questionResults]).then(() => results)
}
