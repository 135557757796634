import session from './async/session'
import user from './async/user'
import quiz from './async/quiz'

export default function (actions, history, state) {
  actions.session = session(actions, history, state)
  actions.user = user(actions, history, state)
  actions.quiz = quiz(actions, history, state)
  return actions
}
