export const initialState = {}

export default {
  setRevisions: (state = initialState, action) => ({
    ...state,
    [action.collectionId]: action.revisions.map((revision) => [
      { ...revision, data: { ...revision.data, quizCollectionId: action.collectionId } },
    ]),
  }),
  setSubRevisions: (state = initialState, action) => {
    const index = state[action.collectionId].map((r) => r[0].id).indexOf(action.id)
    state[action.collectionId][index][0].loaded = true
    state[action.collectionId][index].splice(
      1,
      0,
      ...action.revisions.map((revision) => ({
        ...revision,
        data: { ...revision.data, quizCollectionId: action.collectionId },
      }))
    )
    return state
  },
  addRevision: (state = initialState, action) => ({
    ...state,
    [action.collectionId]: [[action.revision], ...state[action.collectionId]],
  }),
  addRevisions: (state = initialState, action) => ({
    ...state,
    [action.collectionId]: [
      ...state[action.collectionId],
      ...action.revisions.map((revision) => [{
        ...revision,
        data: { ...revision.data, quizCollectionId: action.collectionId } },
    ])],
  }),
  updateRevision: (state = initialState, action) => {
    if (state[action.collectionId]) {
      const revision = state[action.collectionId].flat().find((r) => r.id === action.revision.id)
      if (revision) {
        revision.description = action.revision.description
        revision.updatedAt = action.revision.updatedAt
      }
    }

    return state
  },
}
