import { createQuestion, updateQuestion, removeQuestion, createRevision } from 'src/services/api'

import { sequencingTypes, pointTypes, endpointGroups } from 'src/constants'
import { questionHasImage } from 'src/services/quizChecks'
import { fileUploads, setQuizUpdateInfo, reportChecks } from './common'

export default function (quiz, state, api, updateQuestionPositionAction) {
  const createAndSaveQuestion = ({ quiz: localQuiz, question, position = null }) => {
    api.loading({ name: endpointGroups.QUIZ_QUESTION })

    const { token } = state.session

    return fileUploads({ id: localQuiz.id, questions: [question] }, token)
      .then((res) => createQuestion(localQuiz.id, res.questions[0], token))
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.questions.push(res.question)
        if (localQuiz.questions.length === 1) {
          // Trust the server updated the firstQuestionId
          localQuiz.firstQuestionId = res.question.id
        }

        if (position) {
          const pos = { x: position?.x || 0, y: position?.y || 0 }
          updateQuestionPositionAction({
            quiz: localQuiz,
            questionId: res.question.id,
            position: pos,
          })
          ;(localQuiz.graphQuestionsPositions || {})[res.question.id] = pos
        }

        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        reportChecks(quiz, localQuiz.id, questionHasImage(question))
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_QUESTION, error })
        throw error
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_QUESTION })
      })
  }

  const updateAndSaveQuestion = ({ quiz: localQuiz, question }) => {
    api.loading({ name: endpointGroups.QUIZ_QUESTION })

    const { token } = state.session
    const updatedQuestion = question

    // remove sequencings from the last question
    if (updatedQuestion.resultEnabled) {
      updatedQuestion.sequencing = []
    }

    if (updatedQuestion.questionType === '_multiChoice') {
      // remove conditional branching
      updatedQuestion.sequencing = updatedQuestion.sequencing.filter(
        (s) => true || s.type !== sequencingTypes.BRANCH // @todo fix this also
      )

      // remove per-answer points, switch to question points, move the max to question points
      if (updatedQuestion.pointsEnabled) {
        if (updatedQuestion.pointsType === pointTypes.answer) {
          updatedQuestion.pointsType = pointTypes.question
          if (updatedQuestion.points === undefined || updatedQuestion.points === 0) {
            updatedQuestion.points = Math.max(...updatedQuestion.answerOptions.map((a) => a.points))
          }
          // no points found -> disable question points
          if (updatedQuestion.points === undefined || updatedQuestion.points === 0) {
            updatedQuestion.pointsEnabled = false
          }
        }
      }
    }

    if (updatedQuestion.questionType === '_singleChoice') {
      updatedQuestion.branchingEnabled = true
      updatedQuestion.answerOptions = [...(updatedQuestion.answerOptions || [])].filter((o) => {
        return !o.conditionType
      })
    }

    return fileUploads({ id: localQuiz.id, questions: [updatedQuestion] }, token)
      .then((res) => updateQuestion(localQuiz.id, res.questions[0], token))
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.questions = localQuiz.questions
          .filter((q) => q.id !== res.question.id)
          .concat(res.question)

        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        reportChecks(quiz, localQuiz.id, questionHasImage(question))
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_QUESTION, error })
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_QUESTION })
      })
  }

  const removeAndSaveQuestion = ({ quiz: localQuiz, questionId }) => {
    api.loading({ name: endpointGroups.QUIZ_QUESTION })

    const { token } = state.session

    return removeQuestion(localQuiz.id, questionId, token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        localQuiz.questions = localQuiz.questions.filter((q) => q.id !== questionId)
        setQuizUpdateInfo(localQuiz, state.session.user)
        quiz.replaceQuiz({ quiz: localQuiz })
        createRevision({ quiz: localQuiz }, token)

        return localQuiz
      })
      .catch((error) => {
        api.error({ name: endpointGroups.QUIZ_QUESTION, error })
      })
      .finally(() => {
        api.finish({ name: endpointGroups.QUIZ_QUESTION })
      })
  }

  return { createAndSaveQuestion, updateAndSaveQuestion, removeAndSaveQuestion }
}
