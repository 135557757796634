import {
  changeQuizOwner,
  shareQuiz as shareQuizService,
  getQuizShareInfo as getQuizShareInfoService,
} from 'src/services/api'

export default function (quiz, state, api) {
  const changeOwner = (toQuiz, newOwnerEmail) => {
    api.loading({ name: 'quiz/owner' })

    return changeQuizOwner(toQuiz.id, newOwnerEmail, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        toQuiz.author = res.user
        toQuiz.ownerUserId = res.user.id
        quiz.replaceQuiz({ quiz: toQuiz })

        return res.user
      })
      .catch((error) => {
        api.error({ name: 'quiz/owner', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/owner' })
      })
  }

  const shareQuiz = (toQuiz, data) => {
    api.loading({ name: 'quiz/share' })

    return shareQuizService(toQuiz.id, data, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        return res
      })
      .catch((error) => {
        api.error({ name: 'quiz/share', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/share' })
      })
  }

  const getQuizShareInfo = (toQuiz) => {
    api.loading({ name: 'quiz/share' })

    return getQuizShareInfoService(toQuiz.id, state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }

        return res
      })
      .catch((error) => {
        api.error({ name: 'quiz/share', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/share' })
      })
  }

  return { changeOwner, shareQuiz, getQuizShareInfo }
}
