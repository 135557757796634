import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { withStore } from '../store'
import Loading from './Loading'

/**
 * @TODO investigate why the roles and loggedIn is triggering
 *       change on the useEffect when they stay the same
 */
let hasFetched = false

const propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired,
  loggedIn: PropTypes.bool,
  loggedInAt: PropTypes.number,
  getProfile: PropTypes.func,
  loading: PropTypes.bool,
  user: PropTypes.object,
}

const AuthGuard = ({ roles, children, loggedIn, loggedInAt, user = {}, getProfile, loading }) => {
  const history = useHistory()

  useEffect(() => {
    if (!loggedIn) {
      history.push('/auth/login')
      return
    }

    if (!hasFetched && Date.now() - loggedInAt > 5 * 60 * 1000) {
      hasFetched = true
      getProfile().catch(() => {
        history.push('/auth/login')
      })
    }

    if (!roles.includes(user.userType)) {
      history.push('/errors/error-401')
    }
  }, [history, roles, loggedIn, loggedInAt, user.userType, getProfile])

  if (loading) {
    return <Loading loading={loading} message="Checking user information" />
  }

  return <>{children}</>
}

AuthGuard.propTypes = propTypes

const mapStateToProps = ({ session: { loggedIn, loggedInAt, user }, api: { loading } }) => ({
  loading: loading.includes('user/get-profile'),
  loggedIn,
  loggedInAt,
  user,
})

const mapActionsToProps = ({ user: { getProfile } }) => ({
  getProfile,
})

export default withStore(mapStateToProps, mapActionsToProps)(AuthGuard)
