import actionGenerator from './actionGen'
import session from '../reducers/session'
import api from '../reducers/api'
import quiz from '../reducers/quiz'
import users from '../reducers/users'
import revisions from '../reducers/revisions'

export default function (dispatch) {
  return {
    session: actionGenerator(session, dispatch),
    api: actionGenerator(api, dispatch),
    quiz: actionGenerator(quiz, dispatch),
    users: actionGenerator(users, dispatch),
    revisions: actionGenerator(revisions, dispatch),
  }
}
