/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import ChatIcon from '@material-ui/icons/ChatOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import EditIcon from '@material-ui/icons/Edit'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import ScheduleIcon from '@material-ui/icons/Schedule'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PeopleIcon from '@material-ui/icons/People'

import { userRoles, quizListSections } from 'src/constants'

export default [
  {
    items: [
      {
        title: 'Recent',
        href: `/quiz/${quizListSections.RECENT}`,
        icon: ScheduleIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
      {
        title: 'All Quizzes',
        href: `/quiz/${quizListSections.ALL}`,
        icon: ChatIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
      {
        title: 'Draft',
        href: `/quiz/${quizListSections.DRAFT}`,
        icon: EditIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
      {
        title: 'Published',
        href: `/quiz/${quizListSections.PUBLISHED}`,
        icon: BeenhereIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
      {
        title: 'Deleted',
        href: `/quiz/${quizListSections.DELETED}`,
        icon: DeleteOutlineIcon,
        roles: [userRoles.regular, userRoles.admin],
        divider: true,
      },
      {
        title: 'Users',
        href: '/users',
        icon: PeopleIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        roles: [userRoles.regular, userRoles.admin],
      },
    ],
  },
]
