export const initialState = {
  loading: [],
  errors: [],
}

export default {
  loading: (state = initialState, action) => ({
    ...state,
    loading: [...state.loading, action.name],
    errors: state.errors.filter((error) => error.name !== action.name),
  }),
  finish: (state = initialState, action) => ({
    ...state,
    loading: state.loading.filter((loading) => loading !== action.name),
  }),
  error: (state = initialState, action) => ({
    ...state,
    loading: state.loading.filter((loading) => loading !== action.name),
    errors: [
      ...state.errors,
      {
        name: action.name,
        error: action.error,
      },
    ],
  }),
  clearError: (state = initialState, action) => ({
    ...state,
    errors: state.errors.filter(({ name }) => name !== action.name),
  }),
}
