import { io } from 'socket.io-client'

let socket
let isConnected = false

export const init = ({ onQuizChange }) => {
  if (!isConnected) {
    const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT
    const ws = io(WS_ENDPOINT, {
      transports: ['websocket'],
    })

    socket = new Promise((resolve, reject) => {
      ws.on('connect', () => {
        isConnected = true
        resolve(ws)
      })

      ws.on('quiz.update', (quiz, userId) => {
        onQuizChange(quiz, userId)
      })

      ws.on('disconnect', () => {
        isConnected = false
      })

      ws.on('error', (error) => {
        reject(error)
      })
    })
  }
}

export const joinQuizChannel = (userId, quizCollectionId) => {
  if (isConnected) {
    socket.then((ws) => {
      ws.emit('quiz.join', {
        action: 'quiz.join',
        userId,
        quizCollectionId,
      })
    })
  }
}

export const leaveQuizChannel = (userId, quizCollectionId) => {
  if (isConnected) {
    socket.then((ws) => {
      ws.emit('quiz.leave', {
        action: 'quiz.leave',
        userId,
        quizCollectionId,
      })
    })
  }
}
