import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { withStore } from 'src/store'
import { init as initWebsocket } from 'src/services/websocket'

const noop = () => {
  /* Do Nothing */
}

const propTypes = {
  onQuizChange: PropTypes.func,
}

const WebSocketProvider = ({ children, onQuizChange = noop }) => {
  useEffect(() => {
    initWebsocket({ onQuizChange })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return children
}

WebSocketProvider.propTypes = propTypes

const mapStateToProps = undefined
const mapActionsToProps = ({ quiz: { quizChange } }) => ({
  onQuizChange: quizChange,
})

export default withStore(mapStateToProps, mapActionsToProps)(WebSocketProvider)
