import { api } from './common'

export const createResult = (quizId, result, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/${quizId}/result`,
    token,
    body: result,
  })

export const updateResult = (quizId, result, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/result/${result.id}`,
    token,
    body: result,
  })

export const removeResult = (quizId, resultId, token) =>
  api({
    method: 'DELETE',
    path: `/builder/quiz/${quizId}/result/${resultId}`,
    token,
  })
