export default (state) => ({
  getErrors: (keys = []) => {
    const searchIn = typeof keys === 'string' ? [keys] : keys
    const result = state.errors.filter((err) => searchIn.includes(err.name))
    return result
  },

  isLoading: (key) => {
    return state.loading.includes(key)
  },
})
