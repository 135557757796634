import set from 'lodash/set'
import get from 'lodash/get'
import * as yup from 'yup'

export const yupToFormErrors = (yupError) => {
  let errors = {}
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return set(errors, yupError.path, yupError.message)
    }
    yupError.inner.forEach((err) => {
      if (!get(errors, err.path)) {
        errors = set(errors, err.path, err.message)
      }
    })
  }
  return errors
}

export const validate = (schema, values) => {
  return schema.validate(values, { abortEarly: false }).catch((err) => {
    throw yupToFormErrors(err)
  })
}

export const hasError = (formState, field) =>
  !!(get(formState.touched, field) && get(formState.errors, field))

function isValidRelativeUrl(url) {
  // Regex to check for valid relative URL structure
  const relativeUrlPattern = /^(\/[a-zA-Z0-9-._~%!$&'()*+,;=:@]+)+(\?[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?(#[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?$/;
  return relativeUrlPattern.test(url);
}

export const registerFormValidationMethods = () => {
  yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
    return this.test('unique', message, (list) => {
      return list.length === new Set(list.map(mapper)).size
    })
  })
  yup.addMethod(yup.string, 'relativeOrAbsoluteUrl', function (message) {
    return this.test('relativeOrAbsoluteUrl', message, (value) => {
      return yup.string().url().isValidSync(value) || isValidRelativeUrl(value);
    })
  })
}

export const moveArrayElement = (arr = [], from, to) => {
  if (arr[from] === undefined || to < 0 || to >= arr.length) {
    return arr
  }

  const removed = arr.filter((_, index) => from !== index)
  return [...removed.slice(0, to), arr[from], ...removed.slice(to)]
}
