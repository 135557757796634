import { cleanStore } from 'src/store/local'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

export const api = async ({ method, path, body, token, formData }) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  if (formData) {
    delete headers['Content-Type']
  }

  if (token) {
    headers.Authorization = token
  }

  const options = {
    method,
    headers,
    body: body && (formData ? body : JSON.stringify(body)),
  }

  const response = await fetch(`${API_ENDPOINT}${path}`, options)

  cleanStore(response.headers.get('x-cleanup-timestamp'))

  if (response.ok) {
    return response.json()
  }

  return response.json().catch(() => {
    throw new Error(`API Error ${response.statusText}`)
  })
}

export default api
