import { quizStatus } from 'src/constants'
import { fileUpload, fileDelete } from 'src/services/api'

export const fileUploads = (quiz, token) => {
  const waitForThis = []

  ;(quiz.questions || []).forEach((question) => {
    ;(question.answerOptions || []).forEach((answerOption) => {
      ;['', 'Mobile'].forEach((type) => {
        const fileField = `file${type}`
        if (answerOption[fileField]) {
          waitForThis.push(
            fileUpload(`answer${type}`, answerOption[fileField], token).then((res) => {
              delete answerOption[fileField]
              answerOption[`imageUrl${type}`] = res.location
            })
          )
        }

        const delField = `del${type}`
        if (answerOption[delField]) {
          waitForThis.push(
            fileDelete(
              `answer${type}`,
              answerOption[delField],
              quiz.id,
              answerOption.id,
              token
            ).then(() => {
              delete answerOption[delField]
            })
          )
        }
      })
    })
  })
  ;(quiz.results || []).forEach((result) => {
    if (result.file) {
      waitForThis.push(
        fileUpload('result', result.file, token).then((res) => {
          delete result.file
          result.imageUrl = res.location
        })
      )
    }

    if (result.delFile) {
      waitForThis.push(
        fileDelete('result', result.delFile, quiz.id, result.id, token).then(() => {
          delete result.delFile
        })
      )
    }
  })

  // Quiz Result Loading
  if (quiz.file) {
    waitForThis.push(
      fileUpload('resultsLoader', quiz.file, token).then((res) => {
        delete quiz.file
        quiz.loadingImageUrl = res.location
      })
    )
  }

  // Slack Bot Image Loading
  if (quiz.slackApi?.file) {
    waitForThis.push(
      fileUpload('botImage', quiz.slackApi.file, token).then((res) => {
        delete quiz.slackApi.file
        quiz.slackApi.botImage = res.location
      })
    )
  }

  return Promise.all(waitForThis).then(() => quiz)
}

export const setQuizUpdateInfo = (quiz, user) => {
  quiz.lastUpdateUser = user
  quiz.lastUpdateUserId = user.id
  quiz.updatedAt = new Date()

  return quiz
}

export const genNewQuiz = (state, data, ownerId = null) => ({
  questions: [],
  results: [],
  ...data,
  ownerUserId: ownerId || state.session.user.id,
  author: state.session.user,
  lastUpdateUser: state.session.user,
  lastUpdateUserId: state.session.user.id,
  status: quizStatus.draft,
  createdAt: new Date(),
  updatedAt: new Date(),
})

export const reportChecks = (quiz, quizId, checkPromise) => {
  checkPromise.then((notifications) => {
    quiz.addNotification({ quizId, notifications })
  })
}
