import { api } from './common'

export const getLockStatus = (quizId, entity, id, section, token) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${quizId}/lock/${entity}/${id}/${section}`,
    token,
  })

export const setLockStatus = (quizId, entity, id, lock, section, token) =>
  api({
    method: 'PATCH',
    path: `/builder/quiz/${quizId}/lock/${entity}/${id}/${section}`,
    body: { lock },
    token,
  })
