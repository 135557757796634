/* eslint no-console: "off" */

export const cleanStore = (cleanIfOlderThan) => {
  if (cleanIfOlderThan && localStorage.getItem('quiz.cleanup_timestamp') < cleanIfOlderThan) {
    localStorage.removeItem('quiz.quiz')
    localStorage.setItem('quiz.cleanup_timestamp', Date.now())
  }
}

export default function (name) {
  const read = (obj, obscured = false) => {
    const data = localStorage.getItem(`quiz.${name}`)
    if (data) {
      try {
        return {
          ...obj,
          ...JSON.parse(obscured ? atob(data) : data),
        }
      } catch (e) {
        return obj
      }
    }
    return obj
  }

  const save = (obj, obscure = false) => {
    try {
      localStorage.setItem(
        `quiz.${name}`,
        obscure ? btoa(JSON.stringify(obj)) : JSON.stringify(obj)
      )
      return obj
    } catch (e) {
      console.log('Quota Exceeded in local storage')
      return obj
    }
  }

  const saveImmutable = (imm) => {
    save(imm.toJS())
    return imm
  }

  return {
    read,
    save,
    saveImmutable,
  }
}
