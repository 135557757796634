import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar } from '@material-ui/core'

import { isDev } from 'src/constants'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  logo: {
    fontFamily: "'Play', sans-serif",
    fontWeight: 700,
    color: '#fff',
    fontSize: '22px',
  },
}))

function Topbar({ className, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color={isDev ? 'secondary' : 'primary'}
    >
      <Toolbar>
        <RouterLink to="/" className={classes.logo}>
          {isDev ? 'Qubl Development' : 'Qubl'}
        </RouterLink>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
