export const groupMemberTypes = Object.freeze({
  OWNER: '_owner',
  MEMBER: '_member',
})

export const isDev = process.env.NODE_ENV !== 'production'

export const userRoles = Object.freeze({
  regular: '_regular',
  admin: '_admin',
})

export const readDateFormat = 'MMM D, YY'
export const dateTimeFormat = 'MMMM D, h:mm A'
