/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'

// eslint-disable-next-line no-undef
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

function GoogleAnalytics() {
  return (
    <Helmet>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GA_MEASUREMENT_ID}');
        `}
      </script>

      <noscript>
        {`
        <iframe src="https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `}
      </noscript>
    </Helmet>
  )
}

export default GoogleAnalytics
