export default (reducer, dispatch) =>
  Object.keys(reducer).reduce(
    (obj, action) => ({
      ...obj,
      [action]: (body) =>
        dispatch({
          ...body,
          type: action,
        }),
    }),
    {}
  )
