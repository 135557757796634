export const questionTypes = Object.freeze({
  freeText: '_freeText',
  multiChoice: '_multiChoice',
  singleChoice: '_singleChoice',
  datePicker: '_datePicker',
  transition: '_transition',
  dropdown: '_dropdown',
  tagInput: '_tagInput',
  custom: '_custom',
  multiDropdown: '_multiDropdown',
  slider: '_slider',
})

export const questionTypesName = Object.freeze({
  _freeText: 'Long Text',
  _multiChoice: 'Multi Select',
  _singleChoice: 'Single Select',
  _datePicker: 'Date Picker',
  _transition: 'Transition',
  _dropdown: 'Dropdown, Single Select',
  _tagInput: 'Tag Input',
  _custom: 'Custom Question',
  _multiDropdown: 'Dropdown, Multi Select',
  _slider: 'Slider',
})

export const questionDropdownNameFormTitle = Object.freeze({
  [questionTypes.dropdown]: 'Single-Select Dropdown',
  [questionTypes.multiDropdown]: 'Multi-Select Dropdown',
})

export const questionTextVariants = Object.freeze({
  SHORT_TEXT: '_shortText',
  COLOR_PICKER: '_colorPicker',
})

export const questionTextVariantsName = Object.freeze({
  [questionTextVariants.SHORT_TEXT]: 'Short Text',
  [questionTextVariants.COLOR_PICKER]: 'Color Picker',
})

export const questionDateTypesName = Object.freeze({
  calendar: 'Calendar',
  dropdown: 'Dropdown',
})

export const pointTypes = Object.freeze({
  question: 'question',
  answer: 'answer',
})

export const customQuestionContent = `
<script>
  /**
   * API Description
   * container - The element where your question is located.
   *   You can refer to any element of your question via container.querySelector('#myCustomInput')
   * value - The initial value
   * variables - The object with captured variables
   * onChange - The method by which you can change the value and select the branch (if any)
   * ex: onChange(e.target.value) or  onChange(e.target.value, 'branch name')
   */

  const input = container.querySelector('#myCustomInput')
  input.value = value
  input.onkeyup = (e) => onChange(e.target.value)
</script>

<input id="myCustomInput" type="text" />
`
