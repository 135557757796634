/* eslint react/prop-types: "off" */

import React, { createContext, useContext, useReducer } from 'react'
import { useHistory } from 'react-router'

import actions from './actions'
import asyncActions from './actions/async'
import { initialState, rootReducer } from './reducers'
import selectors from './selectors'

export const StoreContext = createContext(null)

export const StoreProvider = ({ reducer, initialState: initial, children }) => (
  <StoreContext.Provider value={useReducer(reducer, initial)}>{children}</StoreContext.Provider>
)

const returnEmpty = () => ({})

export const withStore = (mapStateToProps = returnEmpty, mapActionsToProps = returnEmpty) => (
  Component
) => (props) => {
  const [state, dispatch] = useContext(StoreContext)
  const history = useHistory()

  return (
    <Component
      {...mapStateToProps(state, selectors(state), props)}
      {...mapActionsToProps(asyncActions(actions(dispatch), history, state))}
      {...props}
    />
  )
}

export default ({ children }) => (
  <StoreProvider initialState={initialState} reducer={rootReducer}>
    {children}
  </StoreProvider>
)
