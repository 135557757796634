import { fromJS } from 'immutable'
import merge from 'lodash/merge'

import createLocalStorage from '../local'
import { processChange, processChanges } from '../changes'
import {
  updateQuiz,
  replaceLocalQuiz,
  replaceSrvQuiz,
  replaceQuiz,
  removeQuiz,
  getLatestServerVersion,
  createQuiz,
  listFromSrv,
  clearChanges,
} from './quizUtils'

const { read, saveImmutable } = createLocalStorage('quiz')

export const initialState = fromJS(
  read({
    list: [],
    list_srv: [],
    list_local: [],
    notifications: {},
  })
)

export default {
  setList: (state = initialState, action) => saveImmutable(listFromSrv(state, action.list)),
  create: (state = initialState, action) => saveImmutable(createQuiz(state, action.quiz)),
  update: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'merge',
          value: action.change,
        })
      )
    ),
  updateFirstQuestion: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        // Delete the first node will change firstQuestionId and also will trigger
        // delete of sequence from start to first noode that will call this method from onSequenceDeleted on QuizQuestionsGraph
        // So when that happens we need to check if the firstQuestion is still the same or already updated by onQuestionDeleted
        // When the connection from quiz start and node is delete here the fromQuestionId and firstQuestionId
        // will be equal
        if (action.fromQuestionId === currentQuiz.firstQuestionId) {
          return processChange(currentQuiz, {
            action: 'merge',
            value: { firstQuestionId: action.firstQuestionId },
          })
        }
        return currentQuiz
      })
    ),
  addQuestion: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        const question = {
          id: Date.now(),
          answerOptions: [],
          sequencing: [],
          ...action.question,
        }

        let changed = processChange(currentQuiz, {
          action: 'push',
          path: ['questions'],
          value: question,
        })

        if (changed.questions.length === 1) {
          changed = processChange(changed, {
            action: 'update',
            path: ['firstQuestionId'],
            value: question.id,
          })
        }

        return changed
      })
    ),
  addSequencing: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        const changed = processChange(currentQuiz, {
          action: 'push',
          path: ['questions', { id: action.sequencing.questionId }, 'sequencing'],
          value: action.sequencing,
        })
        return changed
      })
    ),
  removeSequencing: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        const changed = processChange(currentQuiz, {
          action: 'splice',
          path: [
            'questions',
            { id: action.sequencing.questionId },
            'sequencing',
            action.sequencing,
          ],
        })
        return changed
      })
    ),
  updateQuestion: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'update',
          path: [
            'questions',
            {
              id: action.question.id,
            },
          ],
          value: action.question,
        })
      )
    ),
  updateAnswerOptionsWeights: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        return action.weights.reduce((quiz, weight) => {
          const updatedQuiz = processChange(quiz, {
            action: 'replace',
            path: [
              'questions',
              {
                id: weight.questionId,
              },
              'answerOptions',
              {
                id: weight.answerId,
              },
              'resultWeights',
              {
                answerId: weight.answerId,
                resultId: weight.resultId,
              },
            ],
            value: weight,
          })

          return updatedQuiz
        }, currentQuiz)
      })
    ),
  removeQuestion: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) => {
        const deleted = currentQuiz.questions.find((q) => q.id === action.questionId)

        let changed = processChange(currentQuiz, {
          action: 'splice',
          path: [
            'questions',
            {
              id: action.questionId,
            },
          ],
        })

        if (changed.firstQuestionId === action.questionId) {
          changed = processChange(changed, {
            action: 'merge',
            value: {
              firstQuestionId:
                (deleted?.sequencing?.length
                  ? deleted.sequencing[0].nextQuestionId
                  : changed.questions[0]?.id) || null,
              // if no questions send null undefined dont update the value
            },
          })
        }

        // Check if there are questions that are pointing to the removed question
        const questionsToUpdate = currentQuiz.questions.filter((question) =>
          (question.sequencing || []).find((seq) => seq.nextQuestionId === action.questionId)
        )

        // Update questions removing the sequencing to the removed question
        if (questionsToUpdate.length) {
          questionsToUpdate.forEach((question) => {
            const sequencing = question.sequencing.filter(
              (seq) => seq.nextQuestionId === action.questionId
            )
            sequencing.forEach((s) => {
              changed = processChange(changed, {
                action: 'splice',
                path: ['questions', { id: s.questionId }, 'sequencing', s],
              })
            })
          })
        }

        return changed
      })
    ),
  reorderResult: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'reorder',
          path: ['results'],
          from: action.from,
          to: action.to,
        })
      )
    ),
  addResult: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'push',
          path: ['results'],
          value: {
            id: parseInt(Math.random() * 1000, 10),
            ...action.result,
          },
        })
      )
    ),
  updateResult: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'update',
          path: [
            'results',
            {
              id: action.result.id,
            },
          ],
          value: action.result,
        })
      )
    ),
  removeResult: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'splice',
          path: [
            'results',
            {
              id: action.resultId,
            },
          ],
        })
      )
    ),
  replaceLocal: (state = initialState, action) =>
    saveImmutable(replaceLocalQuiz(state, action.localId, action.quiz.id, action.quiz)),
  replaceSrv: (state = initialState, action) =>
    saveImmutable(replaceSrvQuiz(state, action.quiz.id, action.quiz)),
  replaceQuiz: (state = initialState, action) => saveImmutable(replaceQuiz(state, action.quiz)),
  replaceQuizzes: (state = initialState, action) =>
    saveImmutable(
      (action.quizzes || []).reduce((midState, quiz) => {
        return replaceQuiz(midState, quiz)
      }, state)
    ),
  removeQuiz: (state = initialState, action) => saveImmutable(removeQuiz(state, action.quizId)),
  loadFromSrv: (state = initialState, action) =>
    saveImmutable(
      getLatestServerVersion(state, action.quiz, (changes) => processChanges(action.quiz, changes))
    ),

  clearChanges: (state = initialState, action) =>
    saveImmutable(clearChanges(state, action.quizId, action.times)),
  finishAutosave: (state = initialState, { quiz, times }) => {
    const changesCleanState = clearChanges(state, quiz.id, times)
    const quizReplacedState = replaceQuiz(changesCleanState, quiz)
    return saveImmutable(quizReplacedState)
  },
  addNotification: (state = initialState, action) =>
    saveImmutable(
      state.updateIn(['notifications', action.quizId.toString()], (val) => {
        if (val) {
          // Use merge so its do a deep merge and keeps read attribute
          return fromJS(merge({}, val.toJS(), action.notifications))
        }
        return fromJS(action.notifications)
      })
    ),
  dismissNotification: (state = initialState, action) =>
    saveImmutable(
      state.setIn(['notifications', action.quizId.toString(), action.notificationId, 'read'], true)
    ),
  dismissAllNotification: (state = initialState, action) =>
    saveImmutable(
      state.updateIn(['notifications', action.quizId.toString()], (val) => {
        if (val) {
          return val.map((notificaiton) => notificaiton.set('read', true))
        }
        return undefined
      })
    ),
  setQuizUpdateInfo: (state = initialState, { quizId, user }) =>
    saveImmutable(
      updateQuiz(state, quizId, (currentQuiz) =>
        Object.assign(currentQuiz, {
          lastUpdateUser: user,
          lastUpdateUserId: user.id,
          updatedAt: new Date(),
        })
      )
    ),
  swapQuiz: (state = initialState, action) =>
    saveImmutable(replaceQuiz(removeQuiz(state, action.oldId), action.newQuiz)),
  updateInterstitial: (state = initialState, action) =>
    saveImmutable(
      updateQuiz(state, action.quizId, (currentQuiz) =>
        processChange(currentQuiz, {
          action: 'replace',
          path: ['interstitials', { id: action.interstitial.id }],
          value: action.interstitial,
        })
      )
    ),
}
