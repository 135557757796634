export default {
  root: {
    display: 'flex',
    width: 300,
    '&[class*="fullWidth"]': {
      width: '100%',
    },
    '& > .MuiInputBase-root': {
      width: '100%',
    },
  },
}
