export default {
  root: {},
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)',
  },
  // Fix bug with enabling arrows buttons in the number input in firefox
  input: {
    padding: '18.5px 14px',
    '&[type="number"]': {
      padding: 0,
      margin: '18.5px 14px',
    },
  },
  inputMarginDense: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
    '&[type="number"]': {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 10.5,
      marginBottom: 10.5,
    },
  },
  inputMultiline: {
    padding: 0,
    '&[type="number"]': {
      margin: 0,
    },
  },
}
