const compare = (criteria) => (obj) =>
  Object.entries(criteria).reduce((ret, [key, val]) => ret && obj[key] === val, true)

const getIndex = (arr, path) =>
  // TODO: define behavior for findIndex returning -1
  typeof path === 'object' ? arr.findIndex(compare(path)) : path

const search = (obj, path, cb) => {
  if (!obj) {
    return undefined
  }
  path[0] = getIndex(obj, path[0])
  if (path.length === 1) {
    cb(obj, path[0])
    return obj
  }
  search(obj[path[0]], path.slice(1), cb)
  return obj
}

const actions = {
  push: (obj, { path, value }) =>
    search(obj, path, (o, prop) => {
      o[prop] = o[prop] || []
      o[prop].push(value)
    }),

  update: (obj, { path, value }) => search(obj, path, (o, prop) => (o[prop] = value)),

  delete: (obj, { path }) => search(obj, path, (o, prop) => delete o[prop]),

  splice: (obj, { path }) => search(obj, path, (o, idx) => (idx !== -1 ? o.splice(idx, 1) : o)),

  replace: (obj, { path, value }) =>
    search(obj, path, (o, idx) => (idx !== -1 ? (o[idx] = value) : o.push(value))),

  reorder: (obj, { path, from, to }) =>
    search(obj, path, (o, prop) => {
      const list = o[prop]
      list.splice(to, 0, list.splice(from, 1)[0])
      if (prop === 'results') {
        list.forEach((item, i) => (item.order = i))
      }
    }),

  merge: (obj, { value }) => Object.assign(obj, value),
}

const saveChanges = (obj, params) => {
  obj.changes = obj.changes || []
  params.time = new Date().getTime()
  obj.changes.push(params)
  return obj
}

export const processChange = (obj, params) =>
  saveChanges(actions[params.action](obj, params), params)

export const processChanges = (obj, changes = []) =>
  changes.reduce((objVersion, params) => processChange(objVersion, params), obj)
