/* CONDITIONAL QUESTIONS */
export const DefaultConditionType = Object.freeze({
  LABEL: 'Default',
  VALUE: 'default',
})

export const MultiselectConditionTypes = Object.freeze({
  INCLUDES: 'includes',
  NOTINCLUDES: 'notincludes',
  EQUAL: 'equal',
})
export const MultiselectConditionTypesLabels = Object.freeze({
  [MultiselectConditionTypes.INCLUDES]: 'Includes all or more',
  [MultiselectConditionTypes.NOTINCLUDES]: 'Not includes all',
  [MultiselectConditionTypes.EQUAL]: 'Only includes',
})

// Relate to /shared/enums/ConditionTypes.ts
export const DateConditionTypes = Object.freeze({
  EQUAL: 'equal',
  NOTEQUAL: 'notequal',
  GREATER: 'greater',
  LOWER: 'lower',
})

export const DateConditionTypesLabels = Object.freeze({
  [DateConditionTypes.EQUAL]: 'Equals',
  [DateConditionTypes.NOTEQUAL]: 'Not Equals',
  [DateConditionTypes.GREATER]: 'Greater Than',
  [DateConditionTypes.LOWER]: 'Lower Than',
})

// Relate to /shared/enums/ConditionTypes.ts
export const TextConditionTypes = Object.freeze({
  shared: {
    EQUAL: 'equal',
    NOTEQUAL: 'notequal',
    INCLUDES: 'includes',
    NOTINCLUDES: 'notincludes',
  },
  number: {
    GREATER: 'greater',
    LOWER: 'lower',
  },
  date: {
    GREATER: 'greater',
    LOWER: 'lower',
  },
  'datetime-local': {
    GREATER: 'greater',
    LOWER: 'lower',
  },
})

export const TextConditionTypesLabels = Object.freeze({
  [TextConditionTypes.shared.EQUAL]: 'Equal (=)',
  [TextConditionTypes.shared.NOTEQUAL]: 'Not Equal (!=)',
  [TextConditionTypes.shared.INCLUDES]: 'Includes part of text',
  [TextConditionTypes.shared.NOTINCLUDES]: 'Dont Includes part of text',
  [TextConditionTypes.number.GREATER]: 'Greater Than (>)',
  [TextConditionTypes.number.LOWER]: 'Lower Than (<)',
})

export const CustomConditionTypes = Object.freeze({
  EQUAL: 'equal',
  NOTEQUAL: 'notequal',
  INCLUDES: 'includes',
  NOTINCLUDES: 'notincludes',
  GREATER: 'greater',
  LOWER: 'lower',
  CUSTOM: 'custom',
})
export const CustomConditionTypesLabels = Object.freeze({
  [CustomConditionTypes.EQUAL]: 'Equal (=)',
  [CustomConditionTypes.NOTEQUAL]: 'Not Equal (!=)',
  [CustomConditionTypes.INCLUDES]: 'Includes part of text',
  [CustomConditionTypes.NOTINCLUDES]: 'Dont Includes part of text',
  [CustomConditionTypes.GREATER]: 'Greater Than (>)',
  [CustomConditionTypes.LOWER]: 'Lower Than (<)',
  [CustomConditionTypes.CUSTOM]: 'Set in the code',
})
