import React from 'react'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { registerFormValidationMethods } from 'src/utils/form'

import { theme } from './theme'
import routes from './routes'
import Store from './store'

import GoogleAnalytics from './components/GoogleAnalytics'
import VisualWebsiteOptimizer from './components/VWO'
import CookiesNotification from './components/CookiesNotification'
import ScrollReset from './components/ScrollReset'
import StylesProvider from './components/StylesProvider'

import './components/Sentry'
import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './assets/scss/main.scss'

const history = createBrowserHistory()
registerFormValidationMethods()

function App() {
  return (
    <Store>
      <ThemeProvider theme={theme}>
        <StylesProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <VisualWebsiteOptimizer />
              <CookiesNotification />
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </Store>
  )
}

export default App
