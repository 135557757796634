import spacing from './spacing'

export default {
  formGroup: {
    marginBottom: spacing * 5,
    '& $formLine:last-child, & $formTextLine:last-child': {
      marginBottom: 0,
    },
    '& $formTitle': {
      marginBottom: spacing * 3,
    },
    '& $formTitle$formTextLine': {
      marginBottom: spacing * 1,
    },
  },
  formTitle: {
    fontWeight: 600,
    marginBottom: spacing * 5,
  },
  formLine: {
    marginBottom: spacing * 3,
    '& $formLine:last-child, & $formTextLine:last-child': {
      marginBottom: 0,
    },
    '& $formTitle': {
      marginBottom: spacing * 1,
    },
  },
  formTextLine: {
    marginBottom: spacing * 1,
  },
  formSwitchGroup: {
    marginLeft: 48,
  },
  formRadioGroup: {
    marginLeft: 32,
  },
  formShortGroup: {
    width: 300,
  },
  formInputGroup: {
    '& > * + *': {
      marginTop: spacing * 1,
    },
  },
}
