import generator from './generator'

import sessionReducer, { initialState as sessionInitialState } from './session'

import apiReducer, { initialState as apiInitialState } from './api'

import quizReducer, { initialState as quizInitialState } from './quiz'

import usersReducer, { initialState as usersInitialState } from './users'

import revisionsReducer, { initialState as revisionsInitialState } from './revisions'

export const initialState = {
  session: sessionInitialState,
  api: apiInitialState,
  quiz: quizInitialState,
  users: usersInitialState,
  revisions: revisionsInitialState,
}

export const rootReducer = ({ session, api, quiz, users, revisions }, action) => {
  return {
    session: generator(sessionReducer)(session, action),
    api: generator(apiReducer)(api, action),
    quiz: generator(quizReducer)(quiz, action),
    users: generator(usersReducer)(users, action),
    revisions: generator(revisionsReducer)(revisions, action),
  }
}

export default rootReducer
