import { fromJS } from 'immutable'

export function findIndexBy(list, field, value) {
  return list.findIndex((element) => element.get(field) === value)
}

export function getIndex(list, field, value) {
  const idx = findIndexBy(list, field, value)
  if (idx === -1) {
    throw new Error(`Element not found: ${list}[${field}] = ${value}`)
  }
  return idx
}

export function genList(s) {
  const srv = s.get('list_srv')
  const local = s.get('list_local')
  return s.set(
    'list',
    srv.filter((q) => !local.find((qLocal) => qLocal.get('id') === q.get('id'))).concat(local)
  )
}

export function createQuiz(state, quiz) {
  return state
    .update('list_local', (l) =>
      l.push(
        fromJS({
          ...quiz,
          changes: [],
          local: true,
          new: true,
        })
      )
    )
    .update(genList)
}

export function listFromSrv(state, list) {
  return state.set('list_srv', fromJS(list)).update(genList)
}

export function updateQuiz(state, id, updater) {
  const idx = getIndex(state.get('list'), 'id', id)
  let quizList
  if (state.getIn(['list', idx, 'local'])) {
    quizList = 'list_local'
  } else {
    quizList = 'list_srv'
  }
  const listIdx = getIndex(state.get(quizList), 'id', id)
  const edited = fromJS(updater(state.getIn([quizList, listIdx]).toJS())).set('local', true)

  return state
    .update((s) => {
      if (quizList === 'list_local') {
        return s.setIn([quizList, listIdx], edited)
      }
      return s
        .update('list_srv', (list) => list.delete(listIdx))
        .update('list_local', (list) => list.push(edited))
    })
    .update(genList)
}

export function replaceLocalQuiz(state, localId, srvId, quiz) {
  const localIdx = getIndex(state.get('list_local'), 'id', localId)
  const srvIdx = findIndexBy(state.get('list_srv'), 'id', srvId)
  return state
    .update((s) =>
      s
        .update('list_local', (list) => list.delete(localIdx))
        .update('list_srv', (list) => {
          if (srvIdx === -1) {
            return list.push(fromJS(quiz))
          }
          return list.set(srvIdx, fromJS(quiz))
        })
    )
    .update(genList)
}

export function replaceSrvQuiz(state, srvId, quiz) {
  const srvIdx = findIndexBy(state.get('list_srv'), 'id', srvId)
  return state
    .update((s) =>
      s.update('list_srv', (list) => {
        if (srvIdx === -1) {
          return list.push(fromJS(quiz))
        }
        return list.set(srvIdx, fromJS(quiz))
      })
    )
    .update(genList)
}

export function replaceQuiz(state, quiz) {
  const localIdx = findIndexBy(state.get('list_local'), 'id', quiz.id)
  if (localIdx !== -1) {
    return state
      .update((s) =>
        s.update('list_local', (list) => {
          return list.set(
            localIdx,
            fromJS(quiz)
              .set('local', true)
              .set('changes', list.getIn([localIdx, 'changes']))
          )
        })
      )
      .update(genList)
  }
  return replaceSrvQuiz(state, quiz.id, quiz)
}

export function removeQuiz(state, quizId) {
  let newState = state

  const localIdx = state.get('list_local').findIndex((q) => q.get('id') === quizId)
  if (localIdx !== -1) {
    newState = newState.removeIn(['list_local', localIdx])
  }

  const srvIdx = state.get('list_srv').findIndex((q) => q.get('id') === quizId)
  if (srvIdx !== -1) {
    newState = newState.removeIn(['list_srv', srvIdx])
  }

  return newState.update(genList)
}

export function getLatestServerVersion(state, quiz, applyChanges) {
  let idx
  try {
    idx = getIndex(state.get('list'), 'id', quiz.id)
  } catch (e) {
    return state
      .update((s) => s.update('list_srv', (list) => list.push(fromJS(quiz))))
      .update(genList)
  }
  let list
  if (state.getIn(['list', idx, 'local'])) {
    list = 'list_local'
  } else {
    list = 'list_srv'
  }
  const listIdx = getIndex(state.get(list), 'id', quiz.id)
  return state
    .update((s) =>
      s.setIn(
        [list, listIdx],
        list === 'list_srv'
          ? fromJS(quiz)
          : fromJS(applyChanges(s.getIn([list, listIdx]).get('changes').toJS()))
              .set('local', true)
              .set('changes', s.getIn([list, listIdx]).get('changes'))
      )
    )
    .update(genList)
}

export function getChangesCount(state, id) {
  try {
    const localIdx = getIndex(state.get('list_local'), 'id', id)
    const quiz = state.get('list_local').get(localIdx)
    return quiz.get('new') ? 1 : quiz.get('changes').size
  } catch (e) {
    return 0
  }
}

export function isNew(state, id) {
  try {
    const localIdx = getIndex(state.get('list_local'), 'id', id)
    const quiz = state.get('list_local').get(localIdx)
    return quiz.get('new')
  } catch (e) {
    return false
  }
}

export function getChanges(state, quizId) {
  try {
    const idx = state.get('list').findIndex((q) => q.get('id') === quizId)
    return state.getIn(['list', idx, 'changes']).toJS()
  } catch {
    return []
  }
}

export function clearChanges(state, quizId, times) {
  const idx = state.get('list_local').findIndex((q) => q.get('id') === quizId)
  return state.updateIn(['list_local', idx], (quiz) =>
    quiz.set(
      'changes',
      quiz.get('changes').filter((change) => !times.includes(change.get('time')))
    )
  )
}

export function getQuizFromState(state, quizId) {
  try {
    const idx = state.get('list').findIndex((q) => q.get('id') === quizId)
    return state.getIn(['list', idx]).toJS()
  } catch (e) {
    throw new Error(`Quiz ${quizId} not found`)
  }
}

export function getQuizByCollectionFromState(state, collectionId) {
  try {
    const idx = state.get('list').findIndex((q) => q.get('quizCollectionId') === collectionId)
    return state.getIn(['list', idx]).toJS()
  } catch (e) {
    return undefined
  }
}
